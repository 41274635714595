
import { defineComponent, reactive, watch, toRef } from "vue";
import { useRoute } from 'vue-router'
export default defineComponent({
    components: {
    },
    setup() {
        const route = useRoute()
        const state = reactive({
            selectedKeys: [route.name]
        })
        watch(() => route.name,
            async newName => {
                state.selectedKeys = [newName]
            }
        )
        const selectedKeys = toRef(state, 'selectedKeys')
    
        return {
            selectedKeys
        }
    }
})
