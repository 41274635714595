<template>
    <div class="container ps-3 pe-3">
        <div class="row">
            <div class="col-3">
                <h6 class="my-3">ตั้งค่าสิทธิ์การใช้งาน ระบบ KPI Report</h6>
                <a-card class="w-100" :bodyStyle="{padding: '24px 15px'}">
                    <a-menu theme="light" v-model:selectedKeys="selectedKeys" mode="inline" class="inside-menu-wrapper">
                        <a-menu-item class="m-2" key="kpiReport-accounts" @click="$router.push({name: 'kpiReport-accounts'})">
                            <span class="h6">บัญชีทั้งหมด</span>
                        </a-menu-item>
                        <a-menu-item class="m-2" key="kpiReport-permissions" @click="$router.push({name: 'kpiReport-permissions'})">
                            <span class="h6">จัดการประเภทสิทธิ์การใช้งาน</span>
                        </a-menu-item>
                    </a-menu>
                </a-card>
            </div>
            <div class="col-9">
                <router-view></router-view>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { defineComponent, reactive, watch, toRef } from "vue";
import { useRoute } from 'vue-router'
export default defineComponent({
    components: {
    },
    setup() {
        const route = useRoute()
        const state = reactive({
            selectedKeys: [route.name]
        })
        watch(() => route.name,
            async newName => {
                state.selectedKeys = [newName]
            }
        )
        const selectedKeys = toRef(state, 'selectedKeys')
        return {
            selectedKeys
        }
    }
})
</script>
